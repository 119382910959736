import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../PrivateRoute'
import { UsersScreen } from '../../screens/Users/UsersScreen'
import { UserFormScreen } from '../../screens/Users/UserFormScreen'
import { useSubscription } from '@logux/redux'
import { useAllow } from '../../helpers/hooks/usePermissions'

export const UsersRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['users'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_user')}
        path="/settings/users/new"
        exact
        isAuthenticated={isAuthenticated}
        component={UserFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_user')}
        path="/settings/users/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={UserFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_users')}
        path="/settings/users"
        exact
        isAuthenticated={isAuthenticated}
        component={() => <UsersScreen isLoading={isLoading} />}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
