import * as types from '../../constants/actionTypes/ordersActionTypes'

const emptyOrder = {
  voucher: {}
}
const defaultState = {
  entry: emptyOrder,
  entries: [],
  totalPayed: [],
  filtersEntries: {
    term: '',
    pagination: {
      current: 1,
      pageSize: 10,
      totalCount: 0,
      totalPage: 0
    },
    period: [],
    sort: { by: 'createdAt', order: 'descend' },
    paymentMethods: [],
    productVariantIds: [],
    voucherId: null,
    discountId: null,
    shopId: null,
    countryIds: [],
    stateIds: [],
    stateNames: [],
    paid: null
  },
  requests: {
    sendingEmail: false
  }
}

export default function ordersReducer(state = defaultState, action) {
  switch (action.type) {
    case types.ORDER_FETCH:
      return {
        ...state,
        entry: state.entry.isSelected ? state.entry : action.data
      }
    case types.ORDER_SELECT:
      return {
        ...state,
        entry: {
          ...state.entries.find((u) => u.id === action.id),
          isSelected: true
        }
      }
    case types.ORDER_SEND_EMAIL_RESULT:
      return {
        ...state,
        entry: {
          ...state.entry,
          emailStatsConfirmation: action.data.emailStatsConfirmation
        },
        requests: { ...state.requests, sendingEmail: false }
      }
    case types.ORDER_FORM_RESET:
      return {
        ...state,
        entry: emptyOrder
      }
    case types.ORDER_SEND_EMAIL:
      return {
        ...state,
        requests: { ...state.requests, sendingEmail: true }
      }
    case types.ORDERS_CHANGE_FILTERS: {
      const newFilters = {}
      action.values.forEach((el) => {
        newFilters[el.valueType] = el.value
      })
      return {
        ...state,
        filtersEntries: {
          ...state.filtersEntries,
          ...newFilters
        }
      }
    }
    case types.ORDERS_FETCH:
      return {
        ...state,
        entries: action.data.records,
        totalPayed: action.data.totalPayed,
        filtersEntries: {
          ...state.filtersEntries,
          pagination: {
            ...state.filtersEntries.pagination,
            totalCount: action.data.totalCount,
            totalPage: action.data.totalPage
          }
        }
      }
    case types.ORDER_NOT_FOUND:
      return {
        ...state,
        entry: {
          ...emptyOrder,
          notFound: true
        }
      }
    default:
      return state
  }
}
