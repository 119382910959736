import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return rest.isAuthenticated
        ? (
            rest.allow
              ? (
          <Component {...props} {...rest.componentProps} />
                )
              : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )
          )
        : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
          )
    }}
  />
)
