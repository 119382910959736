import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { WebsitesScreen } from '../../screens/Websites/WebsitesScreen'
import { WebsiteFormScreen } from '../../screens/Websites/WebsiteFormScreen'

export const WebsitesRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['websites'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_website')}
        path="/content/websites/new"
        exact
        isAuthenticated={isAuthenticated}
        component={WebsiteFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_website')}
        path="/content/websites/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={WebsiteFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_websites')}
        path="/content/websites"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={WebsitesScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
