import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { SpecificationFormScreen } from '../../screens/Specifications/SpecificationFormScreen'
import { SpecificationsScreen } from '../../screens/Specifications/SpecificationsScreen'

export const SpecificationsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['specifications', 'specification_groups'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_specification')}
        path="/assortment/specifications/new"
        exact
        isAuthenticated={isAuthenticated}
        component={SpecificationFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_specification')}
        path="/assortment/specifications/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={SpecificationFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_specifications')}
        path="/assortment/specifications"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={SpecificationsScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
