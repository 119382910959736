import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { CategoriesScreen } from '../../screens/Categories/CategoriesScreen'
import { CategoryFormScreen } from '../../screens/Categories/CategoryFormScreen'

export const CategoriesRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['categories'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_category')}
        path="/assortment/categories/new"
        exact
        isAuthenticated={isAuthenticated}
        component={CategoryFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_category')}
        path="/assortment/categories/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={CategoryFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_categories')}
        path="/assortment/categories"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={CategoriesScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
