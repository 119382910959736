import * as types from '../../constants/actionTypes/customersActionTypes'

export const updateCustomer = (dispatch, params, push) => {
  push('/customers')
  dispatch.sync({
    type: types.CUSTOMERS_UPDATE,
    customer: params
  })
}

export const searchCustomer = (dispatch, searchParams, from) => {
  dispatch.sync({
    type: types.CUSTOMER_SEARCH_REQUEST,
    ...searchParams,
    from
  })
}

export const deleteCustomer = (dispatch, id, push) => {
  push('/customers')
  dispatch.sync({
    type: types.CUSTOMERS_DELETE,
    customer: { id }
  })
}

export const createAddress = (dispatch, params) => {
  dispatch.local({
    type: types.CUSTOMER_CREATE_ADDRESS,
    address: params
  })
}

export const deleteAddress = (dispatch, params) => {
  dispatch.local({
    type: types.CUSTOMER_DELETE_ADDRESS,
    address: params
  })
}

export const updateAddress = (dispatch, params) => {
  dispatch.local({
    type: types.CUSTOMER_UPDATE_ADDRESS,
    address: params
  })
}

export const createCustomer = (dispatch, params, push) => {
  dispatch.sync({
    type: types.CUSTOMERS_CREATE,
    customer: params
  })
  push('/customers')
}

export const selectCustomer = (dispatch, id) => {
  dispatch.local({
    type: types.CUSTOMER_SELECT,
    id
  })
}

export const resetCustomer = (dispatch, id) => {
  dispatch.local({
    type: types.CUSTOMER_FORM_RESET,
    id
  })
}

export const changeFiltersCustomers = (dispatch, valueType, value) => {
  dispatch.local({
    type: types.CUSTOMERS_CHANGE_FILTERS,
    valueType,
    value
  })
}
