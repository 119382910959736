import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { ShopsScreen } from '../../screens/Shops/ShopsScreen'
import { ShopFormScreen } from '../../screens/Shops/ShopFormScreen'

export const ShopsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['shops'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_shop')}
        path="/settings/shops/new"
        exact
        isAuthenticated={isAuthenticated}
        component={ShopFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_shop')}
        path="/settings/shops/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={ShopFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_shops')}
        path="/settings/shops"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={ShopsScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
