import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { useAllow } from '../../helpers/hooks/usePermissions'
import { PrivateRoute } from '../PrivateRoute'
import { TagsScreen } from '../../screens/Tags/TagsScreen'
import { TagFormScreen } from '../../screens/Tags/TagFormScreen'

export const TagsRouter = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['tags'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_tag')}
        path="/content/tags/new"
        exact
        isAuthenticated={isAuthenticated}
        component={TagFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_tag')}
        path="/content/tags/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={TagFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_tags')}
        path="/content/tags"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={TagsScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
