import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../PrivateRoute'
import { DiscountsScreen } from '../../screens/Discounts/DiscountsScreen'
import { DiscountFormScreen } from '../../screens/Discounts/DiscountFormScreen'
import { useSubscription } from '@logux/redux'
import { useAllow } from '../../helpers/hooks/usePermissions'

export const DiscountsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['discounts', 'products', 'shops'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_discount')}
        path="/discounts/new"
        exact
        isAuthenticated={isAuthenticated}
        component={DiscountFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_edit_discount')}
        path="/discounts/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={DiscountFormScreen}
        showBreadcrumb={true}
      />
      <PrivateRoute
        allow={allow('can_show_discounts')}
        path="/discounts"
        exact
        isAuthenticated={isAuthenticated}
        component={() => <DiscountsScreen isLoading={isLoading} />}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
