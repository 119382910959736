import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import {
  Card,
  Row,
  Col,
  Form,
  PageHeader,
  Tabs,
  notification
} from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { fm } from '../../lang'
import {
  createProduct,
  deleteProduct,
  resetProduct,
  selectProduct,
  updateProduct
} from '../../data/actions/products'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { usePush } from '../../data/actions/routes'
import { showError } from '../../data/actions/ui'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { PicturesWall } from '../Shared/PicturesWall'
import { BasicFields } from './ProductForm/BasicFields'
import { ProductVariantFields } from './ProductForm/ProductVariantFields'
import { ProductColorsTable } from './ProductColors/ProductColorsTable'
import { ProductSpecLocales } from './ProductForm/ProductSpecLocales'
import { AudioFields } from './ProductForm/AudioFields'
import { TranslationTabs } from '../Shared/Translations/TranslationTabs'
import { TranslationEditor } from '../Shared/Translations/TranslationEditor'
import { TranslationFields } from './Translations/TranslationFields'
import { emptyTranslation } from '../../data/reducers/productsReducer'

const routeTabs = {
  '/assortment/products/:id': 'basic',
  '/assortment/products/:id/colors': 'colors',
  '/assortment/products/:id/colors/new': 'colors',
  '/assortment/products/:id/colors/:colorId': 'colors'
}
const tabRoutes = {
  colors: 'colors'
}

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const ProductForm = ({ isLoading, path }) => {
  const editorRefs = useRef([])
  const supportEditorRefs = useRef([])
  const faqEditorRefs = useRef([])
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.products)
  const mediaGroups = useSelector((state) => state.mediaGroups.entries)
  const { created } = entry
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const { path: routePath } = useRouteMatch()
  const activeTabKey = routeTabs[routePath]

  const handleBack = useCallback(() => {
    push('/assortment/products')
  }, [push])
  const handleTabChange = useCallback((key) => {
    push(`/assortment/products/${id}/${tabRoutes[key] ?? ''}`)
  }, [push, id])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetProduct(dispatch)
        handleBack()
      } else {
        const formEntry = {
          ...entry,
          publishedAt: entry.publishedAt ? moment(entry.publishedAt) : null,
          mediaGroups: mediaGroups.filter((mediaGroup) => mediaGroup.holderType === 'Product')
            .map((group) => entry.mediaGroups.find((item) => item.groupId === group.id) || { groupId: group.id }),
          variants: entry.variants.map((variant) => ({
            ...variant,
            futureDeliveryDate: variant.futureDeliveryDate ? moment(variant.futureDeliveryDate) : null,
            mediaGroups: mediaGroups.filter((mediaGroup) => mediaGroup.holderType === 'ProductVariant')
              .map((group) => variant.mediaGroups.find((item) => item.groupId === group.id) || { groupId: group.id })
          }))
        }

        form.setFieldsValue(formEntry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [isLoading, entry, handleBack, dispatch, form, formValues])

  const onFinish = async() => {
    for (let i = 0; i < editorRefs.current.length; i++) {
      const ref = editorRefs.current[i]
      const data = ref.current && ref.current.save ? await ref.current.save() : null

      if (data) {
        form.setFields([{
          name: ['translations', i, 'editor'],
          value: data
        }])
      }
    }

    for (let i = 0; i < supportEditorRefs.current.length; i++) {
      const ref = supportEditorRefs.current[i]
      const data = ref.current && ref.current.save ? await ref.current.save() : null

      if (data) {
        form.setFields([{
          name: ['translations', i, 'supportEditor'],
          value: data
        }])
      }
    }

    for (let i = 0; i < faqEditorRefs.current.length; i++) {
      const ref = faqEditorRefs.current[i]
      const data = ref.current && ref.current.save ? await ref.current.save() : null

      if (data) {
        form.setFields([{
          name: ['translations', i, 'faqEditor'],
          value: data
        }])
      }
    }

    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      const sendValues = {
        ...values,
        publishedAt: values.publishedAt && values.publishedAt.format(),
        variants: values.variants.map((variant) => ({
          ...variant,
          futureDeliveryDate: variant.futureDeliveryDate && variant.futureDeliveryDate.format('YYYY-MM-DD')
        }))
      }

      if (newRecord) {
        const createParams = { ...entry, ...sendValues }
        await createProduct(dispatch, createParams)
        push(`/assortment/products/${createParams.id}`)
        notification.success({ message: 'Product successfully created' })
      } else {
        const updateParams = { id, ...sendValues }
        await updateProduct(dispatch, updateParams)
        notification.success({ message: 'Product successfully updated' })
      }
    } catch (e) {
      if (!e.action || !e.action.reason) { throw e }

      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }
  const deleteRecord = useCallback(() => {
    deleteProduct(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectProduct(dispatch, id)
    } else if (newRecord) {
      resetProduct(dispatch)
    }
  }, [isLoading, dispatch, newRecord, id])
  const afterUploadFile = (file) => {
    form.setFieldsValue({
      medias: [
        ...form.getFieldValue('medias'),
        {
          blobId: file.uid,
          id: file.parentId ? file.parentId : uuidv4(),
          ...file
        }
      ]
    })
  }
  const afterUploadAudio = (audioIndex, file) => {
    const audio = form.getFieldValue('audios')[audioIndex]
    form.setFieldsValue({
      audios: form.getFieldValue('audios').map((el) => {
        if (el.id === audio.id) {
          return { ...el, blobId: file.uid }
        } else {
          return { ...el }
        }
      })
    })
  }
  const removeMedia = (file) => {
    const oldMedia = form.getFieldValue('medias')
    const newMedia = oldMedia.filter((el) => el.parentId !== file.parentId)
    const oldVariants = form.getFieldValue('variants')
    const newVariants = oldVariants.map((el) => {
      if (el.mediaId === file.parentId) {
        return { ...el, mediaId: '' }
      } else {
        return el
      }
    })
    form.setFieldsValue({ variants: newVariants })
    form.setFieldsValue({ medias: newMedia })
  }
  const cardTitle = newRecord ? 'new' : 'edit'
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`product.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[0, 16]}>
            <Col xs={24}>
              <Form
                form={form}
                layout={'vertical'}
                className={formClass}
                onFinish={onFinish}
              >
                <Col xs={24}>
                  <Tabs
                    defaultActiveKey={activeTabKey}
                    onChange={handleTabChange}
                    items={[{
                      key: 'basic',
                      label: fm('product.form.tabs.basic'),
                      children: <BasicFields isLoading={isLoading} entryId={form.getFieldValue('id')} />
                    }, {
                      key: 'translations',
                      label: fm('product.form.tabs.translations'),
                      children: (
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Card
                              size={'small'}
                              loading={isLoading}
                            >
                              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                                {({ namespace, translation }) =>
                                  <TranslationFields
                                    namespace={namespace}
                                    locale={translation.locale}
                                    isLoading={isLoading}
                                    form={form}
                                  />
                                }
                              </TranslationTabs>
                            </Card>
                          </Col>
                        </Row>
                      )
                    }, {
                      key: 'content',
                      label: fm('product.form.tabs.content'),
                      children: (
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Card
                              size={'small'}
                              loading={isLoading}
                            >
                              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                                {
                                  ({ namespace, translation }) => {
                                    if (!editorRefs.current[namespace]) {
                                      editorRefs.current[namespace] = { current: null }
                                    }

                                    return (
                                      <TranslationEditor
                                        instanceRef={editorRefs.current[namespace]}
                                        data={translation.editor}
                                        uploadPath={`/api/v1/files/products?product_id=${form.getFieldValue('id')}`}
                                        entryId={form.getFieldValue('id')}
                                        namespace={namespace}
                                        pluginList={[
                                          'headerProduct',
                                          'vimeoBlock',
                                          'productBlock',
                                          'htmlBlock',
                                          'productBasicImage',
                                          'productAboutCategory',
                                          'carousel',
                                          'productDoubleImage'
                                        ]}
                                      />
                                    )
                                  }
                                }
                              </TranslationTabs>
                            </Card>
                          </Col>
                        </Row>
                      )
                    }, {
                      key: 'support',
                      label: fm('product.form.tabs.support'),
                      children: (
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Card
                              size={'small'}
                              loading={isLoading}
                            >
                              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                                {
                                  ({ namespace, translation }) => {
                                    if (!supportEditorRefs.current[namespace]) {
                                      supportEditorRefs.current[namespace] = { current: null }
                                    }

                                    return (
                                      <TranslationEditor
                                        instanceRef={supportEditorRefs.current[namespace]}
                                        data={translation.supportEditor}
                                        uploadPath={`/api/v1/files/products?product_id=${form.getFieldValue('id')}`}
                                        entryId={form.getFieldValue('id')}
                                        namespace={namespace}
                                        pluginList={[
                                          'headerProduct',
                                          'vimeoBlock',
                                          'productBlock',
                                          'htmlBlock',
                                          'productBasicImage',
                                          'productAboutCategory',
                                          'carousel',
                                          'productDoubleImage'
                                        ]}
                                      />
                                    )
                                  }
                                }
                              </TranslationTabs>
                            </Card>
                          </Col>
                        </Row>
                      )
                    }, {
                      key: 'faq',
                      label: fm('product.form.tabs.faq'),
                      children: (
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Card
                              size={'small'}
                              loading={isLoading}
                            >
                              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                                {
                                  ({ namespace, translation }) => {
                                    if (!faqEditorRefs.current[namespace]) {
                                      faqEditorRefs.current[namespace] = { current: null }
                                    }

                                    return (
                                      <TranslationEditor
                                        instanceRef={faqEditorRefs.current[namespace]}
                                        data={translation.faqEditor}
                                        uploadPath={`/api/v1/files/products?product_id=${form.getFieldValue('id')}`}
                                        entryId={form.getFieldValue('id')}
                                        namespace={namespace}
                                        pluginList={[
                                          'headerProduct',
                                          'vimeoBlock',
                                          'productBlock',
                                          'htmlBlock',
                                          'productBasicImage',
                                          'productAboutCategory',
                                          'carousel',
                                          'productDoubleImage'
                                        ]}
                                      />
                                    )
                                  }
                                }
                              </TranslationTabs>
                            </Card>
                          </Col>
                        </Row>
                      )
                    }, {
                      key: 'colors',
                      label: fm('product.form.tabs.colors'),
                      children: <ProductColorsTable />
                    }, {
                      key: 'variants',
                      label: fm('product.form.tabs.variants'),
                      children: (
                        <Card size={'small'} loading={isLoading} title={fm('product.variants')}>
                          <Form.Item
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.medias !== curValues.medias
                            }
                          >
                            {(f) => <ProductVariantFields form={f} product={entry} />}
                          </Form.Item>
                        </Card>
                      )
                    }, {
                      key: 'media',
                      label: fm('product.form.tabs.media'),
                      children: (
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <Card
                              size={'small'}
                              loading={isLoading}
                              title={fm('product.media')}
                            >
                              <Form.Item
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.medias !== curValues.medias
                                }
                              >
                                {(f) => {
                                  const medias = f.getFieldValue('medias')
                                  return (
                                    medias && (
                                      <PicturesWall
                                        newRecord={!created}
                                        listType={'picture-card'}
                                        fileType={'media'}
                                        recordType={'product'}
                                        showCodeButton={false}
                                        recordId={entry.id}
                                        afterUploadFile={afterUploadFile}
                                        afterRemoveFile={removeMedia}
                                        files={medias}
                                      />
                                    )
                                  )
                                }}
                              </Form.Item>
                            </Card>
                          </Col>
                        </Row>
                      )
                    }, {
                      key: 'specification',
                      label: fm('product.form.tabs.specification'),
                      children: (
                        <Card size={'small'} loading={isLoading}>
                          <Form.Item noStyle shouldUpdate>
                            {(f) => <ProductSpecLocales entryId={entry.id} form={f} isLoading={isLoading} />}
                          </Form.Item>
                        </Card>
                      )
                    }, {
                      key: 'audio',
                      label: fm('product.form.tabs.audio'),
                      children: (
                        <Card size={'small'} loading={isLoading}>
                          <AudioFields
                            productId={entry.id}
                            created={created}
                            afterUploadAudio={afterUploadAudio}
                          />
                        </Card>
                      )
                    }]}
                  />
                </Col>
                { activeTabKey !== 'colors' &&
                  <FormActionBox
                    isLoading={isLoading}
                    newRecord={newRecord}
                    onDelete={deleteRecord}
                  />
                }
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  )
}
