import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { DashboardScreen } from '../screens/DashboardScreen'
import { useAllow } from '../helpers/hooks/usePermissions'

export const DashboardRoute = ({ isAuthenticated }) => {
  const allow = useAllow()

  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_show_dashboard')}
        path={'/'}
        exact
        isAuthenticated={isAuthenticated}
        component={DashboardScreen}
        showBreadcrumb={true}
      />
    </Switch>
  )
}
