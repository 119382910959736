export const CUSTOMERS_UPDATE = 'customers/update'
export const CUSTOMERS_CREATE = 'customers/create'
export const CUSTOMERS_DELETE = 'customers/delete'
export const CUSTOMER_FETCH = 'customer/fetch'
export const CUSTOMERS_FETCH = 'customers/fetch'
export const CUSTOMER_SELECT = 'customer/select'
export const CUSTOMER_FORM_RESET = 'customer/form-reset'
export const CUSTOMER_CREATE_ADDRESS = 'customer/create-address'
export const CUSTOMER_UPDATE_ADDRESS = 'customer/update-address'
export const CUSTOMER_DELETE_ADDRESS = 'customer/delete-address'
export const CUSTOMER_SEARCH_REQUEST = 'customers/search'
export const CUSTOMER_SEARCH_SUCCESS = 'customers/search-success'
export const CUSTOMERS_CHANGE_FILTERS = 'customers/change-filters'
export const CUSTOMER_NOT_FOUND = 'customer/not_found'
